.fullscreen-background {
  background-image: url("../../Assets/login.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.form-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: calc(100vh - 64px);
  /* Adjust for Navbar height */
  padding: 20px;
}

.signupLogin-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
}

@media (max-width: 768px) {
  .form-wrapper {
    justify-content: center;
    padding: 20px;
  }
}