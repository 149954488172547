/* my changes*/

.candidate {
  height: 150px;
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  /* border: 1px solid black; */
  border-radius: 15px;
  /* background-color: #340134; */
}

.admin {
  height: 150px;
  width: 300px;
  margin: 0 12px;
  border-radius: 15px;
  background: linear-gradient(to right, rgb(137, 104, 255), rgb(175, 152, 255));
  /* background: linear-gradient(to right, #e477db, #df74df, #da71e4, #d36ee9, #cc6cee); */

  position: relative;
  overflow: hidden;
}

.admin::before {
  width: 130px;
  height: 130px;
  content: "";
  background: linear-gradient(to right, rgb(142, 110, 255), rgb(208, 195, 255));
  /* background-image: linear-gradient(to right, #fd68f1, #ca3ec8, #ce55d8, #d369e8, #d77cf7); */
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.hr {
  height: 150px;
  width: 200px;
  margin-left: 12px;
  margin-right: 1px;
  border-radius: 15px;
  background: linear-gradient(to right, rgb(137, 104, 255), rgb(175, 152, 255));
  /* background: linear-gradient(to right, #e477db, #df74df, #da71e4, #d36ee9, #cc6cee); */
  /* background-image: linear-gradient(to right, #ff71f3, #fe82f7, #fd91fa, #fc9ffd, #fbadff); */
  position: relative;
  overflow: hidden;
}

.hr::before {
  width: 120px;
  height: 120px;
  content: "";
  background: linear-gradient(to right, rgb(142, 110, 255), rgb(208, 195, 255));
  /* background-image: linear-gradient(to right, #fd68f1, #ca3ec8, #ce55d8, #d369e8, #d77cf7); */
  /* background-image: linear-gradient(to right, #fd4bee, #fb61f2, #f974f5, #f784f8, #f593fa); */
  position: absolute;
  z-index: 1;
  border-radius: 50%;
  right: -25%;
  top: -25%;
}

.headline {
  text-align: left;
  margin-top: 20px;
  margin-left: 20px;
  color: aliceblue;
  position: relative;
  z-index: 999;
}

.headline1 {
  text-align: left;
  margin-top: 30px;
  padding-top: 20px;
  margin-left: 20px;
  color: aliceblue;
}

.score {
  text-align: left;
  font-size: 30px;
  margin-left: 20px;
  color: aliceblue;
}

.info-icon {
  background-color: #0000;
  margin-left: 180px;
  position: relative;
  z-index: 999;
}

.ab {
  /* background: linear-gradient(to right,rgb(137, 104, 255),rgb(175, 152, 255)); */
  /* Chrome 10-25, Safari 5.1-6 */

}

/* Add some spacing and border */

/* .bc {
    background-color: rgba(51, 153, 255, 1)
}

.cd {
    background-color: rgba(229, 83, 83, 1)
}

.de {
    background-color: rgba(249, 177, 21, 1);
} */

.rejected {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.review {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.selected {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.interview {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.applied {
  /* background-color: rgb(153, 102, 255); */
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);
}

.as {
  height: 10%;
  width: 10px;
}

.line {
  width: 500px;
  height: 100px;
}

.hr.horizontal {
  background-color: transparent;
}

.hr.horizontal.dark {
  background-image: linear-gradient(90deg,
      transparent,
      rgba(0, 0, 0, 0.4),
      transparent);
}

.chart-container {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.linechart {
  margin-top: 2rem;
  margin-right: 60px;
  /* width: 100%; */
  /* flex-direction: row; */
}

.usersicon {
  text-align: right;
  height: 100px;
}

@media (max-width: 767px) {

  /* Styles for mobile devices */
  .candidate,
  .admin,
  .hr {
    height: 120px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .admin,
  .hr {
    width: auto;
  }

  .headline {
    font-size: 16px;
    margin-top: 20px;
    margin-left: 10px;
  }

  .score {
    font-size: 18px;
    margin-left: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  /* Styles for tablets */
  .admin {
    width: 220px;
  }

  .hr {
    width: 300px;
  }
}

@media screen and (min-width: 768px) {

  /* Styles for tablets */
  .admin {
    width: 170px;
  }
}

/* .admincardhover {
  transition: transform 0.3s ease;
}

.admincardhover:hover {
  transform: scale(1.1);
} */

@media (max-width: 576px) {
  .my-element {
    min-width: 100%;
    margin: 3px;
  }

  .scrollable-x {
    overflow-x: auto;
  }

  .imgpersonality {
    width: 8em;
    height: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

@media (min-width: 577px) and (max-width: 1163px) {
  .my-element {
    width: 60vw !important;
  }

  .scrollable-x {
    overflow-x: auto;
  }
}

.btn-light-green {
  background-color: #fdfdfd;
  /* Light green color */
  color: rgb(54, 196, 41);
  outline: rgb(54, 196, 41) 2px solid;
}

.btn-light-green:hover {
  background-color: #242524;
  /* Darker shade for hover effect */
  color: rgb(238, 226, 226);
}