.Input-box {
  width: 65vw !important;
  padding: 0.3em !important;
  border-radius: 18px;
  background-color: white;
}

.InputSvg {
  width: 35px !important;
  right: 20px;
  cursor: pointer;
}

.input-bar {
  border: none;
  font-size: 1em;
}

.card {
  overflow: auto;
  max-width: 100%;
  height: 25em;
  margin-left: 6%;
}

.home-outer-div {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  text-align: center;
  height: calc(100vh - 40px);
  overflow: hidden;
  position: relative;
  background-color: rgba(240, 248, 255, 0.058);
  z-index: 2;
  padding-bottom: 5em;
}

.Career-objective {
  background: linear-gradient(#738cff, #ee11ff);
  -webkit-background-clip: text !important;
  color: transparent !important;
}

.start-here-btn {
  background-color: transparent !important;
  border: 1px solid white;
}

.start-here-btn:hover {
  background-color: black !important;
  border: 1px solid black;
  color: white !important;
}

.searchicon {
  position: absolute;
  right: -46%;
  top: -38px;
  border-radius: 0 5px 5px 0;
}

.admintablesearchicon {
  position: relative;
  left: 41%;
  top: -38.5px;
  border-radius: 0 5px 5px 0;
}

.startdate {
  margin-right: 3px;
  width: 9em;
}

.calender {
  position: relative;
  right: -33%;
  top: -2.4em;
  border-radius: 0 5px 5px 0;
}

.bgvideo {
  position: absolute;
  z-index: -1;
  height: auto;
  width: 100vw;
  top: 0%;
  left: 0%;
}

/* _______________________________________ Profile_picture css_______________________________________  */
.nav-profile-picture-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffffff;
}

.nav-profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.bell-icon {
  position: relative;
  cursor: pointer;
}

.bell-badge {
  position: absolute;
  top: -5px;
  right: 11px;
  font-size: 0.6em;
}

/*____________________________________ Candidate Dashboard Progress Step_________________________________________*/
.progress-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
}

.progress-step-container {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.progress-icon {
  background-color: #b4b8bb;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  z-index: 1;
}

.progress-step.completed .progress-icon {
  background-color: #28a745;
  color: white;
}

.progress-label {
  font-size: 12px;
}

.progress-line {
  flex-grow: 1;
  border-bottom: 5px double #e9ecef;
}

.progress-step.completed~.progress-line {
  border-color: #28a745;
}

.signup-box {
  width: 40%;
}



/* .signup-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 0.5rem;
  width: 90%;
  max-width: 400px;
  margin: auto;
} */


.input-container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border: 2px solid rgb(186, 85, 211);
  border-radius: 4px;
  gap: 0.4em;
  /* background-color: #f6f7f847; */
  /* padding: 0.5em; */
}

.icon-container {
  width: 40px;
  background-color: rgba(105, 95, 95, 0.451);
  padding: 0.9em;
}

.email-input {
  border: none;
  /* padding: 0.7em; */
  background-color: transparent;
  width: 100%;
  outline: none;
  color: grey;
  caret-color: grey;
  /* border: 1px solid blue; */

}