.profile-picture-container {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px; /* Adds spacing below the profile picture */
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 2px solid #ddd; /* Adds a subtle border around the profile picture */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Adds a slight shadow to the picture */
}

.modal-body {
  text-align: center;
}

.modal-body h4 {
  font-size: 1.5rem;
  margin-top: 15px;
}

.modal-body p.text-secondary {
  font-size: 1rem;
  margin-bottom: 15px;
}

.btn-info {
  margin-top: 10px;
}

.btn-danger {
  margin-top: 10px;
  margin-left: 10px; /* Adds space between the "Remove" and "Upload" buttons */
}

/* Add a hover effect to the buttons for better UX */
.btn-info:hover {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-danger:hover {
  background-color: #dc3545;
  border-color: #dc3545;
}
