:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

.bgside.active {
  background-color: rgb(8, 8, 8) !important;
  color: rgb(252, 252, 252) !important;
  margin: 0 0.5em 0 0.5em;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }

  a:hover {
    color: #747bff;
  }

  button {
    background-color: #f9f9f9;
  }
}

.btn-dark-green {
  color: #000000;
  background-color: #32cd32;
  border-color: #32cd32;
}

.btn-dark-green:hover {
  color: #000000;
  background-color: #32cd32;
  border-color: #101110;
}

.btn-faint-green {
  color: #000000;
  background-color: #98fb98;
  border-color: #98fb98;
}

.btn-faint-green:hover {
  color: #000000;
  background-color: #98fb98;
  border-color: #161816;
}

.btn-faint-violet {
  color: #000000;
  background-color: #fd5c63;
  border-color: #fd5c63;
}

.btn-faint-violet:hover {
  color: #000000;
  background-color: #fd5c63;
  border-color: #080808;
}

.btn-dark-violet {
  color: #000000;
  background-color: #ff033e;
  border-color: #ff033e;
}

.btn-dark-violet:hover {
  color: #000000;
  background-color: #ff033e;
  border-color: #080808;
}

.btn-ashgray {
  background: #ffd700;
  color: #000000;
}

.btn-ashgray:hover {
  background: #ffd700;
  color: #000000;
  border-color: #080808;
}

.sidebar {
  z-index: 999;
}

/* .gradient-navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; 
} */

.dashbtnm {
  width: 20em;
}

.feedbacklist {
  height: 100vh;
}

/* ______________________________________dashnotification _________________________________________ */
.notification-item {
  margin-bottom: 15px;
}

.notification-item p {
  margin: 0;
  padding: 0;
}

.notification-msg {
  font-weight: 550;
}

.notification-time {
  font-size: 0.8rem;
  color: #999;
}

.notification-item {
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-item.unseen {
  background-color: #f8d7da;
  border-left: 5px solid #dc3545;
}

.notification-item.seen {
  background-color: #d4edda;
  border-left: 5px solid #28a745;
}

.notification-item:hover {
  background-color: #f0f0f0;
}

/* __________________________________________MAIN____________________________________________ */
.wrapper {
  display: flex;
}

.main {
  height: 100vh;
  max-width: 100vw;
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  transition: all 0.35s ease-in-out;
  background-color: #ffffff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* background-color: rgba(73, 170, 187, 0.4); */
}