.signups-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url("../../Assets/signup.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.signups-content {
  display: flex;
  justify-content: center;
  /* Center by default */
  align-items: center;
  flex-grow: 1;
  padding: 20px;
}

.signups-box {
  background: rgba(255, 255, 255, 0.9);
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  transition: all 0.3s ease;
}

/* Styles for larger devices (desktop) */
@media (min-width: 768px) {
  .signups-content {
    justify-content: flex-end;
    /* Move to the right for larger screens */
    padding-right: 50px;
    /* Add some spacing from the right edge */

  }
}

/* Styles for smaller devices (mobile/tablet) */
@media (max-width: 767px) {


  .signups-content {
    justify-content: center;
    /* Center for smaller screens */
    padding: 20px;

  }
}