@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap");

body,
html,
.App,
#root,
.auth-wrapper {
  font-family: "Poppins", sans-serif;
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

body {
  min-height: 100vh;
  overflow: auto;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  opacity: 0.9;
}

.auth-inner-sign {
  max-width: 450px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
  opacity: 0.9;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.gradient-navbar {
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%);
  /* background-image: linear-gradient(90deg, #ff7e5f, #a060d3);; */
}

.fade-in {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #868686;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* 
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1); 
}

input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(1); 
} */

.gradient-text {
  background: linear-gradient(45deg, #00ffff, #0000ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.wrap-input-8 .input {
  width: 100%;
  box-sizing: border-box;
  letter-spacing: 1px;
  background-color:
    #f5f6fd;
  border: 1px solid #ccc;
  padding: 7px 14px 9px;
  transition: 0.4s;
}

.wrap-input-8 .input:focus {
  outline: none;
}

.wrap-input-8 {
  width: 100%;
  /* margin: 40px 3%; */
  position: relative;
}

.wrap-input-8 .input~.focus-border:before,
.wrap-input-8 .input~.focus-border:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6c5dd3;
  transition: 0.3s;
}

.wrap-input-8 .input~.focus-border:after {
  top: auto;
  bottom: 0;
  left: auto;
  right: 0;
}

.wrap-input-8 .input~.focus-border i:before,
.wrap-input-8 .input~.focus-border i:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0;
  background-color: #6c5dd3;
}

.wrap-input-8 .input~.focus-border i:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.wrap-input-8 .input:focus~.focus-border:before,
.wrap-input-8 .input:focus~.focus-border:after {
  width: 100%;
  transition: 0.3s;
}

.wrap-input-8 .input:focus~.focus-border i:before,
.wrap-input-8 .input:focus~.focus-border i:after {
  height: 100%;
  transition: 0.4s;
}

.btn-shiny2 {
  /* width: 160px; */
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 100%;
  width: 70%;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-shiny2:hover {
  background-position: 100% 0;
  moz-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.btn-shiny2:focus {
  outline: none;
}

.btn-shiny2 {
  background: linear-gradient(90deg, #ff7e5f, #a060d3);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);


}

button:focus,
button:active {
  opacity: .85;
  box-shadow: none;
}

button svg {
  width: 1.25rem;
  height: 1.25rem;
}

.input-container {
  position: relative;
}

.input {
  font-size: 1em;
  padding: 0.6em 1em;
  border: none;
  border-radius: 6px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;
  color: #333;
}

.input:hover {
  background-color: #f2f2f2;
}

.input:focus {
  outline: none;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.input::placeholder {
  color: #999;
}

.highlight {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #6c63ff;
  transition: width 0.3s ease;
}

.input:focus+.highlight {
  width: 100%;
}

/* Optional: Animation on focus */
@keyframes input-focus {
  from {
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }

  to {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.input:focus {
  animation: input-focus 0.3s ease;
}

.btn-pro {
  width: 6.5em;
  height: 2.3em;
  margin: 0.5em;
  background: black;
  color: white;
  border: none;
  border-radius: 0.625em;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

button-pro:hover {
  color: black;
}

button-pro:after {
  content: "";
  background: white;
  position: absolute;
  z-index: -1;
  left: -20%;
  right: -20%;
  top: 0;
  bottom: 0;
  transform: skewX(-45deg) scale(0, 1);
  transition: all 0.5s;
}

button-pro:hover:after {
  transform: skewX(-45deg) scale(1, 1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.button-dwn {
  position: relative;
  width: 150px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #17795E;
  background-color: #209978;
  overflow: hidden;
}

.button,
.button-dwn__icon,
.button-dwn__text {
  transition: all 0.3s;
}

.button-dwn .button-dwn__text {
  transform: translateX(2px);
  color: #fff;
  font-weight: 600;
}

.button-dwn .button-dwn__icon {
  position: absolute;
  transform: translateX(90px);
  height: 100%;
  width: 39px;
  background-color: #17795E;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-dwn .svg {
  width: 20px;
  fill: #fff;
}

.button-dwn:hover {
  background: #17795E;
}

.button-dwn:hover .button-dwn__text {
  color: transparent;
}

.button-dwn:hover .button-dwn__icon {
  width: 148px;
  transform: translateX(0);
}

.button-dwn:active .button-dwn__icon {
  background-color: #146c54;
}

.button-dwn:active {
  border: 1px solid #146c54;
}

.card-user {
  --main-color: #000;
  --submain-color: #78858F;
  --bg-color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
  max-width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: var(--bg-color);
}

.card-user__img {
  height: 192px;
  width: 100%;
}

.card-user__img svg {
  height: 100%;
  border-radius: 20px 20px 0 0;
}

.card-user__avatar {
  position: absolute;
  width: 114px;
  height: 114px;
  background: var(--bg-color);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(38% - 57px);
}

.card-user__avatar svg {
  width: 100px;
  height: 100px;
}

.card-user__title {
  margin-top: 20%;
  font-weight: 500;
  font-size: 20px;
  color: var(--main-color);
}

.card-user__subtitle {
  margin-top: 10px;
  font-weight: 400;
  font-size: 18px;
  color: var(--submain-color);
}

.card-user__btn {
  margin-top: 15px;
  width: 76px;
  height: 31px;
  border: 2px solid var(--main-color);
  border-radius: 4px;
  font-weight: 700;
  font-size: 15px;
  color: var(--main-color);
  background: var(--bg-color);
  text-transform: uppercase;
  transition: all 0.3s;
}

.card-user__btn-solid {
  background: var(--main-color);
  color: var(--bg-color);
}

.card-user__btn:hover {
  background: var(--main-color);
  color: var(--bg-color);
}

.card-user__btn-solid:hover {
  background: var(--bg-color);
  color: var(--main-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type="number"] {
  -moz-appearance: textfield;
}